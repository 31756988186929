import React from "react";
import styles from "./home.module.css";
import { ArrowRight } from 'react-feather';
import { useForm } from "react-hook-form";
import axios from "axios";
import { Button, Snackbar } from "@mui/material";

function Home () {
  const [loader, setLoader] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState('');
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const { register: register1, handleSubmit: handleSubmit1,reset: reset1, formState: { errors:errors1 } } = useForm();

  const onSubmit = (data) =>{
    setLoader(true);
    axios.post("https://dev-user.propertydek.com/api/v1/mod-email-subscription", data).then(response => {
      setLoader(false);
      setSnackMessage("Thank you. We've added your email to the subcription list!");
      setSnackOpen(true);
      reset({email:''});
      reset1({email:''});
    }).catch(error => {
      setLoader(false);
      if(error.response && error.response.data){
        setSnackMessage(error.response.data.message);
        setSnackOpen(true);
      }else{
        setSnackMessage("Error Occured. Please try again.");
        setSnackOpen(true);
      }
    })
  }
  
  return (<React.Fragment>
      <div className={`${styles.MainSec}`}>
        <img src="/img/LineGraY.svg" width="auto" height="1100px" className={`${styles.LineGra}`} alt="" />
        <div className={`${styles.LandPageContainer}`}>
        
          <div className={`${styles.HeadLogoSec}`}>
            <h1 className={`${styles.LogoText}`}>MOD ZRO</h1>
          </div>

          <div className={`${styles.HeadTextSec}`}>
            <h2 className={`${styles.HeadTitleText}`}>Net Zero<br/> Modular Homes</h2>
            <p className={`${styles.HeadConText}`}>We combine off-site modular building techniques with advance energy design <br/>principles to build affordable, electrified smart homes.<br/>
Subscribe to receive updates and join our community!</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.HeadFormSec}`}>
                <input type="email" placeholder="Enter your email" {...register("email", { 
                  required: 'This field can not be blank',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                  }
                })} />
                <button type="submit" className={`${styles.HeadFormBU}`}><ArrowRight /></button>
                {errors.email && <span className={`${styles.ErrorM}`}>{errors?.email?.message}</span>}
              </div>
            </form>
          </div>

          <div className={`${styles.HeadIMGSec}`}>
            <img src="/img/landing-full-img.jpg" width="100%" height="600px" className={`${styles.Banner}`} alt="" />
            <img src="/img/landingHighlightBG.png" className={`${styles.BannerGra}`} alt="" />
          </div>

          <div className={`${styles.OfferSec}`}>
            <p className={`${styles.BlogTileTT}`}>Our Approach</p>
            <div className={`${styles.OfferRow}`}>
              <div className={`${styles.OfferDivSec}`}>
                <div className={`${styles.OfferDivSecUnder}`}>
                  <img src="/img/OfferIMG1.jpg" width="auto" height="500px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.OfferCardTitle}`}>All-Electric Home Experience</p>
                  <p className={`${styles.OfferCardTT}`}>Net zero homes are designed to produce as much energy as they consume over the course of a single year.</p>
                  <p className={`${styles.OfferCardTT}`}>This is achieved through a combination of energy-efficient construction practices, energy-efficient appliances, smart technologies for improved efficiency and on-site renewable energy generation, such as solar panels.</p>
                  <p className={`${styles.OfferCardTT}`}>The goal of net zero homes is to reduce their carbon footprint and energy cost, making them more sustainable and eco-friendly.</p>
                </div>
              </div>
              <div className={`${styles.OfferDivSec}`}>
                <div className={`${styles.OfferDivSecUnder}`}>
                  <img src="/img/OfferIMG2.jpg" width="auto" height="500px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.OfferCardTitle}`}>Sustainable Modular Construction</p>
                  <p className={`${styles.OfferCardTT}`}>The resurgence of modular construction has been mainly attributed to the rising cost of construction and shortage of skilled construction workers.</p>
                  <p className={`${styles.OfferCardTT}`}>Steel framing systems are utilized to construct modules in a factory and then transported to the home site for assembly.</p>
                  <p className={`${styles.OfferCardTT}`}>Key benefits for this approach include increased speed of delivery, improved quality, & reduction of material waste.</p>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.ValueSec}`}>
            <p className={`${styles.ValueTileTT}`}>Our Values</p>
            <p className={`${styles.ValueTileSmallTT}`}>Democratizing net zero, all-electric smart homes.</p>
            <div className={`${styles.ValueRow}`}>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon1.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>New Zero, Renewable Energy</p>
                </div>
              </div>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon2.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>Smart Home</p>
                </div>
              </div>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon3.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>Modular Construction</p>
                </div>
              </div>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon4.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>Community Workforce Development</p>
                </div>
              </div>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon5.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>Affordable Home Ownership</p>
                </div>
              </div>
              <div className={`${styles.ValueCard}`}>
                <div className={`${styles.ValueCardUnder}`}>
                  <img src="/img/ValueIcon6.svg" width="auto" height="56px" className={`${styles.Img}`} alt="" />
                  <p className={`${styles.ValueCardTT}`}>Building Communities</p>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.SubscribeSec}`}>
            <img src="/img/LineGraB.svg" width="auto" height="360px" className={`${styles.LineGraB}`} alt="" />
            <div className={`${styles.SubscribeSecUnder}`}>
              <p className={`${styles.SubscribeSecTitle}`}>Be the First to See It!</p>
              <p className={`${styles.SubscribeSecSMTitle}`}>Subscribe to receive updates and join our community!</p>
              <form onSubmit={handleSubmit1(onSubmit)}>
                <div className={`${styles.SubscribeFormSec}`}>
                  <input type="email" placeholder="Enter your email" {...register1("email", { 
                  required: 'This field can not be blank',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                  }
                })} />
                  <button type="submit" className={`${styles.SubmitBU}`}>Subscribe</button>
                {errors1.email && <span className={`${styles.ErrorM}`}>{errors1?.email?.message}</span>}
                </div>
              </form>
            </div>
          </div>

          <div className={`${styles.FooterSec}`}>
            <p className={`${styles.FooterLogo}`}>MOD ZRO</p>
            <p className={`${styles.FooterTT}`}>© Copyright 2023</p>
          </div>

        </div>       
      </div>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackOpen} autoHideDuration={6000} onClose={() => setSnackOpen(false)} message={snackMessage} action={
        <Button color="secondary" className="YellowColor" size="small" onClick={() => setSnackOpen(false)}>OK</Button>
      } />
    </React.Fragment>);
}

export default Home;